import { omit } from 'ramda';
import to from 'await-to-js';
import {
  networkError, tryCatchNetworkError, breakNumber, validatePhoneNumber,
} from '@twnel/web-components';
import {
  companyLogin, verifyLogin, checkNameAvailability, registerCompany,
} from 'src/data/actions';
import { getAppInfo } from 'src/data/selectors';
import { validateSessionData } from 'src/data/storage';
import { defaultOrigin, goToURL } from 'src/data/util';
import { USERID } from './constants';

const ERROR = {
  PHONE: 'We couldn\'t find your phone number, please make sure you\'re registered as an agent.',
  EMAIL: 'We couldn\'t find your email, please make sure you\'re registered as an agent.',
  OTP: 'The code you entered is invalid.',
  PASSWORD: 'The username or password you entered is incorrect.',
  NETWORK: 'We are having trouble reaching our servers. Please try again later.',
  CORRUPT_DATA: 'We encountered an unusual error. Please try again later or contact support.',
  COMPANY_NAME: 'Please enter the name of the company',
  COMPANY_INDUSTRY: 'Please select the company\'s industry',
  COMPANY_LOGO: 'Please upload the company\'s logo',
  COMPANY_NAME_TAKEN: 'This name is already registered',
  AGENT_NAME: 'Please enter your name',
  AGENT_EMAIL: 'Please enter a valid email address',
  AGENT_PHONE: 'Please enter a valid number',
  AGENT_ROLE: 'Please select your role in the company',
  AGENT_EMAIL_TAKEN: 'This email is already registered',
};

const isEmail = (email) => /^\S+@\S+\.\S+$/.test(email);

const parseUserId = (userId, country) => {
  if (isEmail(userId)) {
    return { id: userId, type: USERID.EMAIL };
  }
  const { number, countryCallingCode } = breakNumber(userId, country);
  if (number && countryCallingCode) {
    return { id: `+${countryCallingCode}${number}`, type: USERID.PHONE };
  }
  return { id: userId, type: USERID.USERNAME };
};

export const login = (userId, defaultCountry) => async (dispatch) => {
  const { id, type } = parseUserId(userId, defaultCountry);
  if (type === USERID.PHONE || type === USERID.EMAIL) {
    const [error] = await tryCatchNetworkError(dispatch(companyLogin(id)));
    if (error && error.status === 400) {
      throw networkError(type === USERID.PHONE ? ERROR.PHONE : ERROR.EMAIL);
    } else if (error) {
      throw networkError(ERROR.NETWORK);
    }
  }
  return { userId: id, type };
};

const checkSession = async (getState) => {
  const { hubId } = getAppInfo(getState());
  const [error, dataCheck] = await to(validateSessionData(hubId));
  return !error && dataCheck;
};

const goToApp = ({ getState, landscaping = false }) => {
  const { environment } = getAppInfo(getState());
  goToURL(defaultOrigin({ environment, landscaping }));
};

export const validate = ({
  userId,
  credentials,
  defaultCountry,
  landscaping = false,
}) => async (dispatch, getState) => {
  const { id, type } = parseUserId(userId, defaultCountry);
  const action = type === USERID.USERNAME ? companyLogin : verifyLogin;
  const [error] = await tryCatchNetworkError(dispatch(action(id, credentials)));
  if (error && error.status === 400) {
    throw networkError(type === USERID.USERNAME ? ERROR.PASSWORD : ERROR.OTP);
  } else if (error) {
    throw networkError(ERROR.NETWORK);
  }
  const dataCheck = await checkSession(getState);
  if (!dataCheck) {
    throw networkError(ERROR.CORRUPT_DATA);
  }
  goToApp({ getState, landscaping });
};

const customError = (message, field = 0) => networkError(`${field}:${message}`);

export const breakError = (error) => {
  if (!error) {
    return [];
  }
  const errorObj = error.split(':');
  return [Number.parseInt(errorObj[0], 10), errorObj.slice(1).join('')];
};

export const register = ({ name, industry, logo }) => async (dispatch) => {
  if (!name) {
    throw customError(ERROR.COMPANY_NAME, 1);
  }

  if (!industry) {
    throw customError(ERROR.COMPANY_INDUSTRY, 2);
  }

  if (!logo) {
    throw customError(ERROR.COMPANY_LOGO, 3);
  }

  const [error, nameAvailable] = await to(dispatch(checkNameAvailability(name)));
  if (error) {
    throw customError(ERROR.NETWORK);
  }
  if (!nameAvailable) {
    throw customError(ERROR.COMPANY_NAME_TAKEN, 1);
  }
};

export const registerValidation = (
  companyData,
  agentData,
  defaultCountry,
) => async (dispatch, getState) => {
  const {
    name, email, phoneNumber, role,
  } = agentData;

  if (!name) {
    throw customError(ERROR.AGENT_NAME, 1);
  }

  if (!/^\S+@\S+\.\S+$/.test(email)) {
    throw customError(ERROR.AGENT_EMAIL, 2);
  }

  if (!validatePhoneNumber(phoneNumber, defaultCountry)) {
    throw customError(ERROR.AGENT_PHONE, 3);
  }

  if (!role) {
    throw customError(ERROR.AGENT_ROLE, 4);
  }

  const [error] = await to(dispatch(registerCompany({
    ...companyData,
    ...omit(['name'], agentData),
    agentName: agentData.name,
  })));
  if (error && error.status === 409) {
    throw customError(ERROR.AGENT_EMAIL_TAKEN, 2);
  }
  if (error) {
    throw customError(ERROR.NETWORK);
  }

  const dataCheck = await checkSession(getState);
  if (!dataCheck) {
    throw customError(ERROR.CORRUPT_DATA);
  }

  const destination = { getState };
  if (companyData.terrachat?.landscaping) {
    destination.landscaping = true;
  }
  goToApp(destination);
};
