import {
  thunkify, toPairs, isEmpty, equals, dissoc,
} from 'ramda';
import { sessionStorageFactory, withIframeQuery } from '@twnel/web-components';
import { NAMESPACE } from './constants';
import {
  StorageFactory, logException, isTwnelOrigin, loginOrigin, goToLogin,
} from './util';

const TIMEOUT = {
  CONNECTION: 12000,
  VALIDATION: 6000,
  DEBUG_NAVIGATION: 20000,
};

const SESSION_UPDATED = `${NAMESPACE}/storageUpdate`;
const SESSION_CLEAR = `${NAMESPACE}/storageClear`;

const frameStyle = {
  display: 'none',
  position: 'absolute',
  top: '-999px',
  left: '-999px',
};

const redirect = ({ message, environment, debug = false }) => {
  const navigate = thunkify(goToLogin)(environment);
  if (debug) {
    logException(message);
    logException(`Will redirect in ${Math.round(TIMEOUT.DEBUG_NAVIGATION / 1000)}s`, true);
    setTimeout(navigate, TIMEOUT.DEBUG_NAVIGATION);
  } else {
    navigate();
  }
};

const updateData = (data) => ({
  type: SESSION_UPDATED,
  payload: data,
});

const clearData = () => ({
  type: SESSION_CLEAR,
});

const validateData = (data) => {
  if (data === null || typeof data !== 'object') {
    return false;
  }
  if (isEmpty(data)) {
    return true;
  }
  const {
    aws, selectedCompany, companies, agents,
  } = data;
  return typeof aws === 'object'
    && typeof selectedCompany === 'string'
    && typeof companies === 'object'
    && (agents === undefined || typeof agents === 'object');
};

export function CrossStorage({ prefix, environment, debug }) {
  let connectionTimeout = setTimeout(() => {
    redirect({
      message: 'Unable to connect with the login frame',
      environment,
      debug,
    });
  }, TIMEOUT.CONNECTION);

  const loginURL = loginOrigin({ environment });
  const frame = window.document.createElement('iframe');
  const postAction = (action) => {
    if (!connectionTimeout) {
      frame.contentWindow.postMessage(action, loginURL);
    }
  };

  const storage = sessionStorageFactory(prefix);
  const {
    getState, setState, clear, addStorageEventListener,
  } = storage;

  let sessionValue = null;
  storage.getState = () => {
    let state = getState();
    if (!isEmpty(sessionValue)) {
      state = state || {};
      state[NAMESPACE] = sessionValue;
    }
    return state;
  };

  storage.setState = (state) => {
    const value = state?.[NAMESPACE];
    if (!equals(sessionValue, value) && validateData(value)) {
      sessionValue = value;
      postAction(updateData(value));
    }
    setState(dissoc(NAMESPACE, state));
  };

  storage.clear = () => {
    postAction(clearData());
    clear();
  };

  const storageListeners = [];
  storage.addStorageEventListener = (listener) => {
    storageListeners.push(listener);
    addStorageEventListener(listener);
  };

  const { hostname: loginHostname } = new URL(loginURL);
  window.addEventListener('message', ({ origin = '', data = {} } = {}) => {
    const { hostname } = new URL(origin);
    if (hostname !== loginHostname || data.type !== SESSION_UPDATED) {
      return;
    }
    if (connectionTimeout) {
      clearTimeout(connectionTimeout);
      connectionTimeout = null;
    }
    if (!validateData(data.payload) || isEmpty(data.payload)) {
      redirect({
        message: `Session data is not present in: ${loginURL}`,
        environment,
        debug,
      });
      return;
    }
    sessionValue = data.payload;
    storageListeners.forEach((callback) => {
      callback();
    });
  });

  toPairs(frameStyle).forEach(([key, value]) => {
    frame.style[key] = value;
  });
  window.document.body.appendChild(frame);
  frame.src = withIframeQuery(loginURL);

  return storage;
}

export function StorageHub({ prefix, environment }) {
  const storage = StorageFactory(prefix);

  let prevSessionData = null;
  const updateClient = () => {
    const sessionData = storage.getState()?.[NAMESPACE];
    if (!equals(prevSessionData, sessionData)) {
      prevSessionData = sessionData;
      window.parent.postMessage(updateData(sessionData), '*');
    }
  };

  const messageListener = ({ origin = '', data = {} } = {}) => {
    if (!isTwnelOrigin(origin, environment)) {
      return;
    }
    if (data.type === SESSION_CLEAR) {
      storage.clear();
    } else if (data.type === SESSION_UPDATED && validateData(data.payload)) {
      const state = storage.getState() || {};
      state[NAMESPACE] = data.payload;
      storage.setState(state);
    }
  };

  return {
    listen() {
      window.addEventListener('message', messageListener);
      storage.addStorageEventListener(updateClient);
      updateClient();
    },
  };
}

export const validateSessionData = (prefix) => {
  const startStamp = Date.now();
  const storage = StorageFactory(prefix);
  return new Promise((resolve, reject) => {
    let checkTimeout;
    (function check() {
      const sessionData = storage.getState()?.[NAMESPACE];
      if (sessionData) {
        clearTimeout(checkTimeout);
        resolve(!isEmpty(sessionData) && validateData(sessionData));
      } else if (Date.now() - startStamp >= TIMEOUT.VALIDATION) {
        clearTimeout(checkTimeout);
        reject();
      } else {
        checkTimeout = setTimeout(check, 200);
      }
    }());
  });
};
