import {
  curry, curryN, assoc, includes,
} from 'ramda';
import to from 'await-to-js';
import { networkError } from '@twnel/web-components';
import { companyAutolockerConfiguration } from '@twnel/utils-js/lib/web';
import { uploadS3 } from '@twnel/utils-js/lib/aws';
import { UPDATE_AGENT } from '../constants';
import { logException } from '../util';

export const updateAgent = curry((company, agent) => ({
  type: UPDATE_AGENT,
  payload: { company, agent },
}));

export const fetchCompanyAgents = curryN(2, async (
  api,
  company,
  { agentId, pickFields } = {},
) => {
  const [error, agents] = await to(api.agents.get(company, { agentId, pickFields }));
  if (error) {
    throw networkError(error);
  }
  return agents;
});

export const updateAgentPhoto = async (aws, api, company, agent, photo) => {
  const { url } = await uploadS3({ aws, file: photo });
  return api.agents.update([assoc('photo', url, agent)], { company });
};

export const fetchOnlineAgents = async (api, company, agents) => {
  const [resultAgents, onlineIds] = await Promise.all([
    Promise.resolve(agents),
    api.agents.getOnline(company).catch(() => {
      logException('fetchOnlineAgents: Unable to fetch the list of online agents');
      return [];
    }),
  ]);
  return resultAgents.map((agent) => {
    const online = includes(agent.id, onlineIds);
    return agent.online !== online
      ? assoc('online', online, agent)
      : agent;
  });
};

export const fetchAutolockAvailableAgents = async (api, company, agents) => {
  const resolvedCompany = await Promise.resolve(company);
  const { enabled: autolockerEnabled } = companyAutolockerConfiguration(resolvedCompany);
  if (!autolockerEnabled) {
    return agents;
  }
  const [resultAgents, availableIds] = await Promise.all([
    Promise.resolve(agents),
    api.agents.getAutolockAvailable(resolvedCompany).catch(() => {
      logException('fetchAutolockAvailableAgents: Unable to fetch the list of available agents');
      return [];
    }),
  ]);
  return resultAgents.map((agent) => {
    const autolock = includes(agent.id, availableIds);
    return agent.autolock !== autolock
      ? assoc('autolock', autolock, agent)
      : agent;
  });
};
