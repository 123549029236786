import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import isMobile from 'is-mobile';
import { StyledLink } from 'src/ui/components/util';
import { useResources } from 'src/ui/connect/hooks';

export default function Header() {
  const mobile = isMobile();
  const { texts } = useResources();
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        maxWidth: '1080px',
        padding: '15px 10px 40px',
        alignItems: 'center',
        boxSizing: 'border-box',
      }}
    >
      <a
        href="https://terrachat.ai/"
        style={{
          display: 'flex',
          alignItems: 'center',
          width: mobile ? '50px' : 'auto',
          overflow: 'hidden',
          fontSize: '1.75rem',
          textDecoration: 'none',
        }}
      >
        <img
          height="50"
          style={{
            borderRadius: '50%',
          }}
          srcSet="
            https://twnelpublic.s3.us-west-2.amazonaws.com/TerraChat.png,
            https://twnelpublic.s3.us-west-2.amazonaws.com/TerraChat%402x.png 2x,
            https://twnelpublic.s3.us-west-2.amazonaws.com/TerraChat%403x.png 3x,
          "
          src="https://twnelpublic.s3.us-west-2.amazonaws.com/TerraChat.png"
          alt="Terra"
        />
        <span
          style={{
            color: '#51AD6A',
            margin: '0 0.15rem 0 0.25rem',
          }}
        >
          Terra
        </span>
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          Chat
        </span>
      </a>
      <div style={{ flexGrow: 1 }} />
      <Switch>
        <Route path="/terra/register">
          <StyledLink
            as={Link}
            to={(location) => ({ ...location, pathname: '/terra' })}
          >
            {texts('Manage your business')}
          </StyledLink>
        </Route>
        <Route path="/terra">
          <StyledLink
            as={Link}
            to={(location) => ({ ...location, pathname: '/terra/register' })}
          >
            {texts('Create a free account')}
          </StyledLink>
        </Route>
      </Switch>
    </div>
  );
}
