import { assoc, mergeWith } from 'ramda';
import { SESSION_MIDDLEWARE_UPDATE } from '@twnel/web-components';
import { UPDATE_AGENT } from '../constants';
import { listReducer } from './util';

const agentsReducer = listReducer({
  updateSingle: UPDATE_AGENT,
  refreshList: SESSION_MIDDLEWARE_UPDATE,
});

export default function (state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case SESSION_MIDDLEWARE_UPDATE:
      return mergeWith(
        (agents, newAgents) => agentsReducer(agents, {
          type: SESSION_MIDDLEWARE_UPDATE,
          payload: newAgents,
        }),
        state,
        payload,
      );
    case UPDATE_AGENT: {
      const { company, agent } = payload;
      const { id: companyId } = company;
      const updatedAgents = agentsReducer(
        state[companyId],
        { type, payload: agent },
      );
      return assoc(companyId, updatedAgents, state);
    }
    default:
      return state;
  }
}
