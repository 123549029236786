import { compose, last, prop } from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import isMobile from 'is-mobile';
import { TextInput, Avatar, LoadingButton } from '@twnel/web-components';
import { breakError } from '../connect/actions';
import { useResources, useImageURL, useRegisterValidation } from '../connect/hooks';
import Dropdown from './Dropdown';
import { InputField, AvatarInput } from './util';

const managerRole = ['MANAGER', 'Manager'];
const AGENT_ROLES = [
  ['MARKETING_PROFESSIONAL', 'Marketing professional'],
  ['MARKETING_MANAGER', 'Marketing manager'],
  ['CUSTOMER_SERVICE_PROFESSIONAL', 'Customer service professional'],
  ['CUSTOMER_SERVICE_MANAGER', 'Customer service manager'],
  ['SALES_PROFESSIONAL', 'Sales professional'],
  ['SALES_MANAGER', 'Sales manager'],
  ['IT_SPECIALIST', 'IT Specialist'],
  ['DIRECTOR', 'Director'],
  managerRole,
  ['OTHER', 'Other'],
];

const AgentProfile = ({ hideImage, companyData, landscaping }) => {
  const mobile = isMobile();
  const { texts, defaultCountry } = useResources();

  const [roles, setRoles] = React.useState([]);
  React.useEffect(() => {
    setRoles(AGENT_ROLES.map(compose(texts, last)));
  }, [texts]);

  const [name, setName] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [phoneNumber, setPhoneNumber] = React.useState(null);
  const [role, setRole] = React.useState(null);
  const [photo, setPhoto] = React.useState(null);

  const photoURL = useImageURL(photo);
  const registerValidationProps = useRegisterValidation();
  const {
    loading, error, onSubmit, clearError,
  } = registerValidationProps;

  React.useEffect(() => {
    hideImage(!!error);
  }, [hideImage, error]);

  React.useEffect(() => {
    clearError();
  }, [name, email, phoneNumber, role, clearError]);
  const [errorLabel, errorMessage] = breakError(error);

  return (
    <>
      <h2>{texts('Almost there!')}</h2>
      <p style={{ margin: '0 0 20px' }}>
        {texts('Pop in your info and you\'re ready to roll with TerraChat.')}
      </p>
      <InputField mobile={mobile}>
        <TextInput
          placeholder={texts('Your name')}
          onChange={compose(setName, prop('value'))}
          autoFocus
          boxed
        />
        <div>{errorLabel === 1 ? errorMessage : null}</div>
      </InputField>
      <InputField mobile={mobile}>
        <TextInput
          placeholder={texts('Work email')}
          onChange={compose(setEmail, prop('value'))}
          autoFocus={false}
          boxed
        />
        <div>{errorLabel === 2 ? errorMessage : null}</div>
      </InputField>
      <InputField mobile={mobile}>
        <TextInput
          type="tel"
          placeholder={texts('Phone number')}
          defaultCountry={defaultCountry}
          onChange={compose(setPhoneNumber, prop('value'))}
          autoFocus={false}
          strict
          boxed
        />
        <div>{errorLabel === 3 ? errorMessage : null}</div>
      </InputField>
      {landscaping ? null : (
        <>
          <InputField mobile={mobile}>
            <Dropdown
              label={texts('Role')}
              options={roles}
              onChange={(index) => setRole(AGENT_ROLES[index][0])}
            />
            <div>{errorLabel === 4 ? errorMessage : null}</div>
          </InputField>
          <AvatarInput as="div">
            <Avatar
              id="placeholder2"
              avatarType="agent"
              size={300}
              image={photoURL}
              editable
              updateImage={setPhoto}
            />
          </AvatarInput>
        </>
      )}
      <InputField
        mobile={mobile}
        style={{ margin: '20px 0' }}
      >
        <LoadingButton
          loading={loading}
          onClick={() => onSubmit(companyData, {
            name,
            email,
            phoneNumber,
            role: landscaping ? managerRole[0] : role,
            photo,
          })}
        >
          {texts('Finish')}
        </LoadingButton>
        <div>{errorLabel === 0 ? errorMessage : null}</div>
      </InputField>
    </>
  );
};

AgentProfile.propTypes = {
  hideImage: PropTypes.func.isRequired,
  companyData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    industry: PropTypes.string.isRequired,
    logo: PropTypes.instanceOf(Blob),
  }).isRequired,
  landscaping: PropTypes.bool,
};

AgentProfile.defaultProps = {
  landscaping: false,
};

export default AgentProfile;
