import { compose, prop } from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import {
  TextInput, FlatButton, LoadingButton, useTheme,
} from '@twnel/web-components';
import { STEP } from '../connect/constants';
import { useResources, useValidate } from '../connect/hooks';

const Password = ({ userId, setStep }) => {
  const [password, setPassword] = React.useState('');
  const { texts } = useResources();
  const validateProps = useValidate();
  const {
    loading, error, onSubmit, clearError,
  } = validateProps;

  const change = React.useMemo(
    () => compose(clearError, setPassword, prop('value')),
    [clearError],
  );
  const submit = React.useMemo(
    () => (password.length > 3 ? () => onSubmit(userId, password) : undefined),
    [userId, password, onSubmit],
  );
  const cancel = React.useCallback(
    () => setStep(STEP.LOGIN),
    [setStep],
  );

  const theme = useTheme();
  return (
    <>
      <div>
        <h2 style={{ marginBottom: '2rem' }}>
          { texts('Enter your password') }
        </h2>
        <p style={{ fontSize: '1rem', lineHeight: '1.65rem' }}>
          { texts('You are signing in as ') }
          <strong style={{ whiteSpace: 'nowrap' }}>
            { userId }
          </strong>
          .&nbsp;
          <FlatButton onClick={cancel}>
            { texts(' Not me') }
          </FlatButton>
        </p>
      </div>
      <div style={{ margin: '2rem 0' }}>
        <TextInput
          type="password"
          onChange={change}
          onSubmit={submit}
          autoFocus
        />
      </div>
      { error ? (
        <p
          style={{
            fontSize: '1rem',
            lineHeight: '1.65rem',
            color: theme.error,
          }}
        >
          { texts(error) }
        </p>
      ) : null }
      <div style={{ margin: '2rem 0' }}>
        <LoadingButton
          disabled={loading || !submit}
          loading={loading}
          onClick={submit}
        >
          { texts('Next') }
        </LoadingButton>
      </div>
    </>
  );
};

Password.propTypes = {
  userId: PropTypes.string.isRequired,
  setStep: PropTypes.func.isRequired,
};

export default Password;
