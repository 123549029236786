export const USERID = {
  PHONE: 'phone',
  EMAIL: 'email',
  USERNAME: 'username',
};

export const STEP = {
  LOGIN: 'login',
  VALIDATE: 'validate',
  PASSWORD: 'password',
};

export const LANDSCAPING_INDUSTRY = 'LANDSCAPING';
