import React from 'react';
import isMobile from 'is-mobile';
import OTPValidation from 'src/ui/components/OTPValidation';
import UserId from 'src/ui/components/UserId';
import { STEP } from 'src/ui/connect/constants';

export default function Login() {
  const mobile = isMobile();
  const [step, setStep] = React.useState(STEP.LOGIN);
  const [userId, setUserId] = React.useState('');

  let component;
  if (step === STEP.VALIDATE) {
    component = (
      <OTPValidation
        userId={userId}
        setStep={setStep}
        landscaping
      />
    );
  } else {
    component = (
      <UserId
        userId={userId}
        setUserId={setUserId}
        setStep={setStep}
        landscaping
      />
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        maxWidth: '1080px',
        alignItems: 'center',
        padding: '0 10px',
        boxSizing: 'border-box',
      }}
    >
      <div
        style={{
          width: '376px',
          padding: '20px',
          boxSizing: 'border-box',
        }}
      >
        { component }
      </div>
      {mobile ? null : (
        <>
          <div style={{ flexGrow: 1 }} />
          <img
            height="500"
            style={{ marginTop: '-2.5rem' }}
            src="https://twnelpublic.s3.us-west-2.amazonaws.com/Terra_Login.png"
            alt=""
          />
        </>
      )}
    </div>
  );
}
