import { compose, prop } from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import { TextInput, LoadingButton, useTheme } from '@twnel/web-components';
import { useResources, useLogin } from '../connect/hooks';

const UserId = ({
  userId,
  setUserId,
  setStep,
  landscaping,
}) => {
  const { texts, defaultCountry } = useResources();
  const loginProps = useLogin(defaultCountry, setStep);
  const {
    loading, error, onSubmit, clearError,
  } = loginProps;

  const change = React.useMemo(
    () => compose(clearError, setUserId, prop('value')),
    [setUserId, clearError],
  );
  const submit = React.useMemo(
    () => (userId.length > 3 ? () => onSubmit(userId) : undefined),
    [userId, onSubmit],
  );

  const theme = useTheme();
  return (
    <>
      <h1>{ texts('Welcome!') }</h1>
      <p style={{ fontSize: '1rem', lineHeight: '1.65rem' }}>
        { texts('Sign in to manage your business') }
      </p>
      <div style={{ margin: '2rem 0' }}>
        <TextInput
          initialValue={userId}
          placeholder={texts(landscaping ? 'Email address' : 'Phone, email or username')}
          type="tel"
          strict={false}
          defaultCountry={defaultCountry}
          onChange={change}
          onSubmit={submit}
          autoFocus
        />
      </div>
      { error ? (
        <p
          style={{
            fontSize: '1rem',
            lineHeight: '1.65rem',
            color: theme.error,
          }}
        >
          { texts(error) }
        </p>
      ) : null }
      <div style={{ margin: '2rem 0' }}>
        <LoadingButton
          disabled={loading || !submit}
          loading={loading}
          onClick={submit}
        >
          { texts('Next') }
        </LoadingButton>
      </div>
    </>
  );
};

UserId.propTypes = {
  userId: PropTypes.string,
  setUserId: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  landscaping: PropTypes.bool,
};

UserId.defaultProps = {
  userId: '',
  landscaping: false,
};

export default UserId;
