import { pickExisting } from '@twnel/web-components';
import { NAMESPACE } from '../constants';
import { getAllItemsById, getItem, getAllItems } from './util';

const agents = (state) => state[NAMESPACE].agents;

// (companyId | selector) → state → {agent}
export const getAllAgentsById = getAllItemsById(agents);

// (companyId | selector) → (agentId | selector) → state → agent
export const getAgent = getItem(agents);

// (companyId | selector) → state → [agent]
export const getAllAgents = getAllItems(agents);

export const sessionAgent = getItem(
  agents,
  pickExisting(['id', 'role', 'name', 'photo', 'tags', 'metadata']),
);
