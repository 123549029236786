import React from 'react';
import isMobile from 'is-mobile';
import OTPValidation from './components/OTPValidation';
import Password from './components/Password';
import UserId from './components/UserId';
import { STEP } from './connect/constants';

export default function Login() {
  const mobile = isMobile();
  const [step, setStep] = React.useState(STEP.LOGIN);
  const [userId, setUserId] = React.useState('');

  let component;
  if (step === STEP.VALIDATE) {
    component = (
      <OTPValidation
        userId={userId}
        setStep={setStep}
      />
    );
  } else if (step === STEP.PASSWORD) {
    component = (
      <Password
        userId={userId}
        setStep={setStep}
      />
    );
  } else {
    component = (
      <UserId
        userId={userId}
        setUserId={setUserId}
        setStep={setStep}
      />
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        maxWidth: '1080px',
        alignItems: 'center',
        padding: '0 10px',
        boxSizing: 'border-box',
      }}
    >
      <div
        style={{
          width: '376px',
          padding: '20px',
          boxSizing: 'border-box',
        }}
      >
        { component }
      </div>
      {mobile ? null : (
        <>
          <div style={{ flexGrow: 1 }} />
          <img
            height="460"
            style={{ marginTop: '100px' }}
            src="https://www.twnel.com/wp-content/uploads/2019/04/Pricing.png"
            srcSet="
              https://www.twnel.com/wp-content/uploads/2019/04/Pricing-768x900.png,
              https://www.twnel.com/wp-content/uploads/2019/04/Pricing-874x1024.png 2x,
              https://www.twnel.com/wp-content/uploads/2019/04/Pricing.png 3x
            "
            alt=""
          />
        </>
      )}
    </div>
  );
}
