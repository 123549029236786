import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Dropdown as DropdownBase, Box, Item, Input,
} from '@twnel/web-components';

const Container = styled(Input)`
  display: flex;
  align-items: center;
  cursor: pointer;
  .i {
    color: #415161;
  }
`;

const List = styled(Box)`
  max-height: 264px;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ListItem = styled(Item)`
  padding: 14px 20px;
`;

const Dropdown = ({ label, options, onChange }) => {
  const [index, setIndex] = React.useState(-1);
  React.useEffect(() => {
    setIndex(-1);
  }, [options]);
  const itemClick = (itemIndex) => () => {
    setIndex(itemIndex);
    onChange(itemIndex);
  };
  return (
    <DropdownBase
      offsetY={52}
      render={() => (
        <List>
          {options.map((item, itemIndex) => (
            <ListItem
              key={item}
              onClick={itemClick(itemIndex)}
            >
              {item}
            </ListItem>
          ))}
        </List>
      )}
    >
      <Container
        as="div"
        empty={index < 0}
      >
        <div style={{ flexGrow: 1 }}>
          {index >= 0 ? options[index] : label}
        </div>
        <i className="fas fa-caret-down" />
      </Container>
    </DropdownBase>
  );
};

Dropdown.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Dropdown;
