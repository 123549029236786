import React from 'react';
import isMobile from 'is-mobile';
import CompayProfile from './components/CompanyProfile';
import AgentProfile from './components/AgentProfile';

export default function Register() {
  const mobile = isMobile();
  const [hideImage, setHideImage] = React.useState(false);
  const [companyData, setCompanyData] = React.useState(null);

  let component;
  if (companyData) {
    component = (
      <AgentProfile
        hideImage={setHideImage}
        companyData={companyData}
      />
    );
  } else {
    component = (
      <CompayProfile
        hideImage={setHideImage}
        nextStep={setCompanyData}
      />
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        maxWidth: '1080px',
        alignItems: 'center',
        padding: '0 10px',
        boxSizing: 'border-box',
      }}
    >
      <div
        style={{
          width: '376px',
          padding: '20px',
          boxSizing: 'border-box',
        }}
      >
        {component}
      </div>
      {mobile ? null : (
        <>
          <div style={{ flexGrow: 1 }} />
          <img
            height="760"
            style={{
              zIndex: -5,
              marginTop: '100px',
              transform: 'translate(0, 40px)',
              opacity: hideImage ? 0.1 : 1.0,
              transition: 'opacity 0.2s ease-out',
            }}
            src="https://www.twnel.com/wp-content/uploads/2019/03/H-CONTROL.png"
            alt=""
          />
        </>
      )}
    </div>
  );
}
