export const NAMESPACE = '_session';

export const UPDATE_AWS = `${NAMESPACE}/aws`;
export const UPDATE_COMPANY = `${NAMESPACE}/company`;
export const UPDATE_SELECTED_COMPANY = `${NAMESPACE}/selectedCompany`;
export const UPDATE_AGENT = `${NAMESPACE}/agent`;
export const UPDATE_BUSINESS_UNIT = `${NAMESPACE}/businessUnit`;
export const DELETE_BUSINESS_UNIT = `${NAMESPACE}/deleteUnit`;

export const APP_INFO = '_info';
