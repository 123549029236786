import { prop, merge } from 'ramda';
import React from 'react';
import { render } from 'react-dom';
import {
  createStore, combineReducers, applyMiddleware, compose as reduxCompose,
} from 'redux';
import { Provider } from 'react-redux';
import {
  SessionMiddleware, ThunkMiddleware, resourceReducer, loadTexts, loadCountries, IS_IFRAME,
} from '@twnel/web-components';
import {
  Api, Http, Endpoints, getApiHost,
} from '@twnel/utils-js/lib/web';
import dataReducer from './data/reducer';
import { SessionSelector } from './data/selectors';
import { StorageHub } from './data/storage';
import { StorageFactory, appId } from './data/util';
import { APP_INFO } from './data/constants';
import App from './ui';

(function main() {
  // Storage identifier
  const { NODE_ENV, TWNEL_ENV, packageVersion } = process.env;
  const debug = NODE_ENV !== 'production';
  const environment = getApiHost() || TWNEL_ENV;
  const identifier = appId({
    prefix: 'hub',
    version: packageVersion,
    environment,
    debug,
  });

  // iFrame version
  if (IS_IFRAME) {
    const hub = StorageHub({
      prefix: identifier,
      environment,
    });
    hub.listen();
    return;
  }

  // TwnelApi
  const endpoints = Endpoints(environment);
  const api = Api(endpoints, Http());

  // Redux devtools.
  // eslint-disable-next-line no-underscore-dangle
  const devCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  const compose = (debug && devCompose) || reduxCompose;

  // Middleware set up.
  const session = SessionMiddleware({
    selector: SessionSelector(),
    storage: StorageFactory(identifier),
  });
  const initialState = {
    ...session.readState(),
    [APP_INFO]: {
      debug,
      environment,
      version: packageVersion,
      hubId: identifier,
    },
  };
  const thunk = ThunkMiddleware({ api });
  const enhancer = applyMiddleware(thunk, session.middleware);

  // Store set up
  const storeReducer = combineReducers({ ...resourceReducer, ...dataReducer });
  const store = createStore(storeReducer, initialState, compose(enhancer));

  // Load the texts file.
  store.dispatch(loadTexts(async (locale) => {
    let loaders;
    switch (locale) {
      case 'es':
        loaders = [
          import(/* webpackChunkName: "esLocale" */ '@twnel/web-components/lib/es.json'),
          import(/* webpackChunkName: "esLocale" */ 'resources/localization/es.json'),
        ];
        break;
      case 'pt':
        loaders = [
          import(/* webpackChunkName: "ptLocale" */ '@twnel/web-components/lib/pt.json'),
          import(/* webpackChunkName: "ptLocale" */ 'resources/localization/pt.json'),
        ];
        break;
      default:
        return {};
    }
    const result = await Promise.all(loaders);
    return result.map(prop('default')).reduce(merge);
  }));

  // Load country list.
  store.dispatch(loadCountries(
    () => import(/* webpackChunkName: "countries" */ '@twnel/web-components/lib/countries.json'),
  ));

  // Initial render.
  const app = React.createElement(App);
  render(
    React.createElement(Provider, { store }, app),
    document.getElementById('react'),
  );
}());
