import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Input, isDark, brightenColor, darkenColor,
} from '@twnel/web-components';

const StyledLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.actionable};
  font-weight: 500;
  @media (hover: hover) {
    transition: opacity 0.2s ease-out;
    &:hover, &:active {
      opacity: 0.8;
    }
  }
`;

StyledLink.propTypes = {
  theme: PropTypes.shape({
    actionable: PropTypes.string.isRequired,
  }),
};

StyledLink.defaultProps = {
  theme: {
    actionable: '#415161',
  },
};

const InputField = styled.div`
  ${({ mobile }) => (mobile ? '' : `
    display: flex;
    align-items: center;
  `)}
  position: relative;
  margin: 10px 0;
  & > *:last-child {
    color: ${({ theme }) => theme.error};
    font-weight: 500;
    ${({ mobile }) => (mobile ? `
      margin: 10px 0 25px 5px;
    ` : `
      position: absolute;
      right: -10px;
      transform: translate(100%, 0);
    `)}
  }
`;

InputField.propTypes = {
  mobile: PropTypes.bool,
  theme: PropTypes.shape({
    error: PropTypes.string.isRequired,
  }),
};

InputField.defaultProps = {
  mobile: false,
  theme: {
    error: '#C84D59',
  },
};

const AvatarInput = styled(Input)`
  height: 260px;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: ${({ theme }) => theme.input.background};
  background-image: ${({ theme }) => {
    const color = (isDark(theme.input.background)
      ? brightenColor : darkenColor)(theme.input.background);
    return `
      linear-gradient(45deg, ${color} 25%, transparent 25%, transparent 75%, ${color} 75%, ${color}),
      linear-gradient(45deg, ${color} 25%, transparent 25%, transparent 75%, ${color} 75%, ${color});
    `;
  }};
  background-size: 24px 24px;
  background-position: 0 0, 12px 12px;
`;

AvatarInput.propTypes = {
  theme: PropTypes.shape({
    input: PropTypes.shape({
      background: PropTypes.string.isRequired,
      foreground: PropTypes.string.isRequired,
    }),
  }),
};

AvatarInput.defaultProps = {
  theme: {
    input: {
      background: '#FFFFFF',
      foreground: '#172B3F',
    },
  },
};

export {
  StyledLink,
  InputField,
  AvatarInput,
};
