import { assoc, compose } from 'ramda';
import { SESSION_MIDDLEWARE_UPDATE, SESSION_MIDDLEWARE_CLEAR } from '@twnel/web-components';
import { NAMESPACE, UPDATE_AWS, UPDATE_SELECTED_COMPANY } from '../constants';
import companiesReducer from './companies';
import agentsReducer from './agents';
import businessUnitsReducer from './businessUnits';
import { assocUpdated } from './util';

const initialState = () => ({
  aws: undefined,
  selectedCompany: undefined,
  companies: companiesReducer(undefined, { type: '@@INIT' }),
  agents: agentsReducer(undefined, { type: '@@INIT' }),
  businessUnits: businessUnitsReducer(undefined, { type: '@@INIT' }),
});

export default function (state = initialState(), action) {
  const { type, payload } = action;
  switch (type) {
    case SESSION_MIDDLEWARE_UPDATE: {
      const {
        aws, selectedCompany, companies, agents,
      } = payload?.[NAMESPACE] ?? {};
      return compose(
        assoc('aws', aws),
        assoc('selectedCompany', selectedCompany),
        assocUpdated(
          'companies',
          companiesReducer,
          { type: SESSION_MIDDLEWARE_UPDATE, payload: companies ?? {} },
        ),
        assocUpdated(
          'agents',
          agentsReducer,
          { type: SESSION_MIDDLEWARE_UPDATE, payload: agents ?? {} },
        ),
      )(state);
    }
    case SESSION_MIDDLEWARE_CLEAR:
      return initialState();
    case UPDATE_AWS:
      return assoc('aws', payload, state);
    case UPDATE_SELECTED_COMPANY:
      return assoc('selectedCompany', payload, state);
    default: {
      return compose(
        assocUpdated('companies', companiesReducer, action),
        assocUpdated('agents', agentsReducer, action),
        assocUpdated('businessUnits', businessUnitsReducer, action),
      )(state);
    }
  }
}
