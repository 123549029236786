import {
  last, find, compose, reject, isNil,
} from 'ramda';
import { Endpoints } from '@twnel/utils-js/lib/web';
import { localStorageFactory, cookieStorageFactory } from '@twnel/web-components';

export const logException = (error, warning = false) => {
  const errorObj = warning || error instanceof Error ? error : new Error(error);
  // eslint-disable-next-line no-console
  console[warning ? 'warn' : 'error'](errorObj);
};

export const appId = ({
  prefix,
  version = '1.0.0',
  environment,
  debug = false,
} = {}) => {
  const cleanEnvironment = last(environment.split('.')).split('/')[0];
  if (prefix) {
    const versionMatch = typeof version === 'string' && version.match(/^[0-9]+\.[0-9]+/);
    if (!versionMatch) {
      throw new Error(`Unexpected version format, expected [0-9].[0-9](.[0-9])+: ${version}`);
    }
    const cleanVersion = versionMatch[0].replace('.', '_');
    return `${debug ? 'debug_' : ''}${prefix}_${cleanEnvironment}_${cleanVersion}`;
  }
  return `${debug ? 'DEBUG ' : ''}${cleanEnvironment.slice(0, 2)} ${version}`;
};

export const isSessionCompany = ({ userAgent, apikey, token } = {}) => (
  userAgent && (apikey || token)
);

export const isTwnelOrigin = (origin, environment) => {
  let originUrl;
  try {
    originUrl = new URL(origin);
  } catch (error) {
    return false;
  }

  const {
    LOGIN_ORIGIN,
    COMPANIES_ORIGIN,
    METRICS_ORIGIN,
    BOTS_ORIGIN,
    SETTINGS_ORIGIN,
    ALPHA_ORIGIN,
    ROUTING_ORIGIN,
    TERRA_ORIGIN,
  } = Endpoints(environment);

  const { hostname } = originUrl;
  return !!find((url) => {
    const { hostname: urlHostname } = new URL(url);
    return urlHostname === hostname;
  }, reject(isNil, [
    LOGIN_ORIGIN,
    COMPANIES_ORIGIN,
    METRICS_ORIGIN,
    BOTS_ORIGIN,
    SETTINGS_ORIGIN,
    ALPHA_ORIGIN,
    ROUTING_ORIGIN,
    TERRA_ORIGIN,
    'http://localhost',
  ]));
};

export const appOrigin = ({ inSearchParam } = {}) => {
  if (inSearchParam) {
    const urlParams = new URLSearchParams(window.location.search);
    return decodeURIComponent(urlParams.get('origin'));
  }
  return window.location.origin;
};

export const loginOrigin = ({
  environment,
  appendAppOrigin = false,
}) => {
  const { LOGIN_ORIGIN, TERRA_ORIGIN } = Endpoints(environment);
  if (!appendAppOrigin) {
    return LOGIN_ORIGIN;
  }

  const { hostname } = new URL(appOrigin());
  const { hostname: terraHostname } = new URL(TERRA_ORIGIN);
  const originQuery = `origin=${encodeURIComponent(appOrigin())}`;
  if (hostname === terraHostname) {
    return `${LOGIN_ORIGIN}/terra?${originQuery}`;
  }

  return `${LOGIN_ORIGIN}?${originQuery}`;
};

export const defaultOrigin = ({ environment, landscaping = false }) => {
  // Check if a return URL is present as a query param
  const origin = appOrigin({ inSearchParam: true });
  if (isTwnelOrigin(origin, environment)) {
    return origin;
  }

  // Redirect to the terra client
  const { TERRA_ORIGIN, COMPANIES_ORIGIN } = Endpoints(environment);
  if (landscaping && TERRA_ORIGIN) {
    return TERRA_ORIGIN;
  }

  // Redirect to the companies client by default
  return COMPANIES_ORIGIN;
};

export const goToURL = (url) => {
  if (!/^https?:\/\/[^\s]{2,}$/i.test(url)) {
    throw new Error(`Tried to navigate to an invalid url: ${url}`);
  }
  window.location.replace(url);
};

export const goToLogin = compose(
  goToURL,
  loginOrigin,
  (environment) => ({ environment, appendAppOrigin: true }),
);

export const isSafari = () => navigator.userAgent.indexOf('Safari') !== -1
  && navigator.userAgent.indexOf('Chrome') === -1;

export const StorageFactory = (prefix) => (
  isSafari() ? cookieStorageFactory(prefix) : localStorageFactory(prefix)
);
