import { SESSION_MIDDLEWARE_UPDATE } from '@twnel/web-components';
import { UPDATE_COMPANY } from '../constants';
import { listReducer } from './util';

const companiesReducer = listReducer({
  updateSingle: UPDATE_COMPANY,
  refreshList: SESSION_MIDDLEWARE_UPDATE,
});

export default companiesReducer;
