import { assoc, dissoc, mergeDeepRight } from 'ramda';

export const assocUpdated = (prop, reducer, action) => (state) => {
  const stateProp = reducer(state[prop], action);
  return stateProp === state[prop] ? state : assoc(prop, stateProp, state);
};

const objectReducer = (updateSingle) => (state = {}, action) => {
  const { type, payload } = action;
  if (type === updateSingle) {
    return mergeDeepRight(state, payload);
  }
  return state;
};

export const listReducer = ({
  updateSingle,
  deleteSingle,
  refreshList,
}) => {
  const reducer = objectReducer(updateSingle);
  return (state = {}, action) => {
    const { type, payload } = action;
    if (refreshList && type === refreshList && payload) {
      return mergeDeepRight(state, payload);
    }
    if (updateSingle && type === updateSingle && payload) {
      const { id } = payload;
      return assoc(id, reducer(state[id], action), state);
    }
    if (deleteSingle && type === deleteSingle && payload) {
      const { id } = payload;
      return dissoc(id, state);
    }
    return state;
  };
};
