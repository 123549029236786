import { compose } from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import {
  CodeInput, FlatButton, LoadingButton, formatPhoneNumber, useTheme,
} from '@twnel/web-components';
import { STEP } from '../connect/constants';
import { useResources, useValidate } from '../connect/hooks';

const OTP_LENGTH = 4;

const OTPValidation = ({ userId, setStep, landscaping }) => {
  const [otp, setOTP] = React.useState('');
  const { texts } = useResources();
  const validateProps = useValidate(landscaping);
  const {
    loading, error, onSubmit, clearError,
  } = validateProps;

  const change = React.useMemo(
    () => compose(clearError, setOTP),
    [clearError],
  );
  const submit = React.useCallback(
    onSubmit(userId),
    [userId, onSubmit],
  );
  const cancel = React.useCallback(
    () => setStep(STEP.LOGIN),
    [setStep],
  );

  const theme = useTheme();
  return (
    <>
      <div>
        <h2 style={{ marginBottom: '2rem' }}>
          { texts('Verify it\'s you') }
        </h2>
        <p style={{ fontSize: '1rem', lineHeight: '1.65rem' }}>
          { texts('You will shortly receive a code sent to ') }
          <strong style={{ whiteSpace: 'nowrap' }}>
            { formatPhoneNumber(userId) }
          </strong>
          &nbsp;
          <FlatButton
            size={{ height: 'inherit', scale: 1.005 }}
            onClick={cancel}
          >
            { texts('Not me') }
          </FlatButton>
        </p>
      </div>
      <div style={{ margin: '2rem 0' }}>
        <CodeInput
          autoFocus
          size={OTP_LENGTH}
          onChange={change}
          onSubmit={submit}
        />
      </div>
      { error ? (
        <p
          style={{
            fontSize: '1rem',
            lineHeight: '1.65rem',
            color: theme.error,
          }}
        >
          { texts(error) }
        </p>
      ) : null }
      <div style={{ margin: '2rem 0' }}>
        <LoadingButton
          disabled={loading || otp.length < OTP_LENGTH}
          loading={loading}
          onClick={() => submit(otp)}
        >
          { texts('Next') }
        </LoadingButton>
      </div>
    </>
  );
};

OTPValidation.propTypes = {
  userId: PropTypes.string.isRequired,
  setStep: PropTypes.func.isRequired,
  landscaping: PropTypes.bool,
};

OTPValidation.defaultProps = {
  landscaping: false,
};

export default OTPValidation;
