import {
  values, prop, map, filter, compose, cond, isEmpty, always, T as True,
} from 'ramda';
import { createSelector } from 'reselect';
import { smartSelector, uncurrySelector, pickExisting } from '@twnel/web-components';
import { isSessionCompany } from 'src/data/util';
import { NAMESPACE } from '../constants';

const companiesById = (state) => state[NAMESPACE].companies;

// (companyId | selector) → state → company
export const getCompany = uncurrySelector(2, (companyId) => smartSelector(
  companyId,
  companiesById,
  prop,
));

const getAllCompanies = createSelector(
  companiesById,
  values,
);

// state → [company]
export const getUserCompanies = createSelector(
  getAllCompanies,
  filter(isSessionCompany),
);

// state → [company]
export const sessionCompanies = createSelector(
  companiesById,
  cond([
    [isEmpty, always(undefined)],
    [True, compose(
      map(pickExisting(['id', 'apikey', 'token', 'userAgent', 'name', 'logo'])),
      filter(isSessionCompany),
    )],
  ]),
);
