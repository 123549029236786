import React from 'react';
import {
  BrowserRouter, Switch, Route, Redirect,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createGlobalStyle } from 'styled-components';
import { TwnelContext } from '@twnel/web-components';
import { getAppInfo } from 'src/data/selectors';
import { appId } from 'src/data/util';
import Header from './Header';
import Login from './Login';
import Register from './Register';
import TerraLogin from './terra/Login';
import TerraRegister from './terra/Register';
import TerraHeader from './terra/Header';

const GlobalStyle = createGlobalStyle`
  html, body, #react {
    height: 100%;
    margin: 0;
  }
  #react {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const enableRegister = ({ environment }) => environment === 'stage'
  || environment === 'beta'
  || environment === 'debug';

export default function App() {
  const appInfo = useSelector(getAppInfo);
  const showRegister = enableRegister(appInfo);
  return (
    <TwnelContext>
      <GlobalStyle />
      <BrowserRouter>
        <Switch>
          <Route path="/terra">
            <TerraHeader />
          </Route>
          <Route path="/">
            <Header showRegister={showRegister} />
          </Route>
        </Switch>
        <div style={{ flexGrow: 1 }} />
        <Switch>
          {showRegister ? (
            <Route path="/register">
              <Register />
            </Route>
          ) : null}
          <Route exact path="/terra/register">
            <TerraRegister />
          </Route>
          <Route path="/terra">
            <TerraLogin />
          </Route>
          <Route exact path="/">
            <Login />
          </Route>
          <Redirect to="/" />
        </Switch>
        <div style={{ flexGrow: 1 }} />
        <div
          style={{
            width: '100%',
            maxWidth: '1080px',
            padding: '90px 10px 10px',
            boxSizing: 'border-box',
            textAlign: 'right',
            fontWeight: 'bold',
            fontSize: '0.9rem',
            opacity: '0.4',
          }}
        >
          {appId(appInfo)}
        </div>
      </BrowserRouter>
    </TwnelContext>
  );
}
