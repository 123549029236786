import {
  assoc, tryCatch, compose, always, head, omit,
} from 'ramda';
import { batch } from 'react-redux';
import to from 'await-to-js';
import { networkError, SESSION_MIDDLEWARE_CLEAR } from '@twnel/web-components';
import { parseApiCompany, allAgentFields } from '@twnel/utils-js/lib/web';
import { UPDATE_AWS } from '../constants';
import { logException, isSessionCompany, goToLogin } from '../util';
import { updateAgent, fetchCompanyAgents, updateAgentPhoto } from './agents';
import { updateCompany, setSelectedCompany } from './companies';

const clearData = () => ({ type: SESSION_MIDDLEWARE_CLEAR });

const cleanAWS = (data) => {
  const { key, secret } = data;
  if (key && secret) {
    return { key, secret };
  }
  const { Key, Secret } = data;
  if (Key && Secret) {
    return { key: Key, secret: Secret };
  }
  logException('cleanAWS: Missing AWS credentials in data');
  throw networkError(500);
};

const processLogin = (data) => async (dispatch, getState, getContext) => {
  const { AWS, agents, companies } = data;
  if (!AWS || !agents || !companies) {
    logException('processLogin: Unexpected session data');
    throw networkError(500); // Unexpected server response
  }

  const processCompany = (() => {
    const agentData = agents.reduce((result, { company, phoneNumber, token } = {}) => (
      company && phoneNumber ? assoc(company, { phoneNumber, token }, result) : result
    ), {});
    return (company) => (company ? {
      ...company,
      userAgent: agentData[company.id].phoneNumber,
      token: agentData[company.id].token,
      lastUpdate: Date.now(),
    } : null);
  })();

  const filteredCompanies = companies
    .map(tryCatch(compose(processCompany, parseApiCompany), always({})))
    .filter(isSessionCompany);
  if (filteredCompanies.length < 1) {
    logException('processLogin: Unable to parse session data, no user-companies found');
    throw networkError(400); // Related companies not found
  }

  const { api } = getContext();
  const selectedCompany = head(filteredCompanies);
  const userAgent = await fetchCompanyAgents(api, selectedCompany, {
    agentId: selectedCompany.userAgent,
    pickFields: allAgentFields,
  });

  const aws = cleanAWS(AWS);
  batch(() => {
    dispatch(clearData());
    dispatch({ type: UPDATE_AWS, payload: aws });
    filteredCompanies.forEach(compose(dispatch, updateCompany));
    dispatch(updateAgent(selectedCompany, userAgent));
    dispatch(setSelectedCompany(selectedCompany.id));
  });
};

export const companyLogin = (userId, password) => async (dispatch, getState, getContext) => {
  const { api } = getContext();
  const [error, data] = await to(api.companies.login(userId, password));
  if (!data) {
    const errorCode = error < 500
      ? 400 // Invalid credentials or user not found
      : 500; // Network/server error
    throw networkError(errorCode);
  }
  if (password) {
    return dispatch(processLogin(data));
  }
  return data;
};

export const verifyLogin = (userId, otp) => async (dispatch, getState, getContext) => {
  const { api } = getContext();
  const [error, data] = await to(api.companies.verifyLogin(userId, otp));
  if (!data) {
    const errorCode = error < 500
      ? 400 // Invalid credentials or user not found
      : 500; // Network/server error
    throw networkError(errorCode);
  }
  return dispatch(processLogin(data));
};

export const logout = (environment) => (dispatch) => {
  dispatch(clearData());
  goToLogin(environment);
};

export const registerCompany = (data) => async (dispatch, getState, getContext) => {
  const { api } = getContext();
  const [registerError, result] = await to(api.companies.register(omit(['photo'], data)));
  if (registerError) {
    const errorCode = registerError === 409
      ? 409 // Agent email already registered
      : 500; // Network/server error
    throw networkError(errorCode);
  }

  const { APIKey: apikey, companyId } = result;
  if (!apikey || !companyId) {
    logException('registerCompany: Missing apikey or companyId in request response');
    throw networkError(500);
  }

  let options;
  const { phoneNumber, photo, terrachat } = data;
  if (terrachat?.landscaping) {
    options = { terrachat };
  }

  const [validateError, rawCompany] = await to(
    api.companies.validateRegister(apikey, options),
  );
  if (validateError) {
    logException('registerCompany: Validation request failed');
    throw networkError(500);
  }

  const aws = cleanAWS(rawCompany.AWS);
  const company = compose(omit(['AWS']), assoc('apikey', apikey))(rawCompany);

  if (photo) {
    const [agentError] = await to(updateAgentPhoto(aws, api, company, { phoneNumber }, photo));
    if (agentError) logException('registerCompany: Failed to upload agent\'s photo', true);
  }

  return dispatch(processLogin({
    AWS: aws,
    companies: [company],
    agents: [{
      company: companyId,
      phoneNumber,
    }],
  }));
};
