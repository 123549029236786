import { identity, curryN } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import {
  debounce, tryCatchNetworkError, getTexts, getCountries,
} from '@twnel/web-components';
import {
  login, validate, register, registerValidation,
} from './actions';
import { USERID, STEP, LANDSCAPING_INDUSTRY } from './constants';

const getDefaultCountry = createSelector(
  getCountries,
  ({ clientCountry }) => clientCountry.code,
);

export const useResources = () => {
  const texts = useSelector(getTexts);
  const defaultCountry = useSelector(getDefaultCountry);
  return { texts, defaultCountry };
};

const useAction = (action, arity = action.length) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const onAction = React.useCallback(async (...args) => {
    setLoading(true);
    const [actionError] = await tryCatchNetworkError(action(...args));
    if (actionError) {
      setError(actionError.message);
      setLoading(false);
    }
  }, [action]);
  const onSubmit = React.useMemo(
    () => curryN(arity, loading ? identity : onAction),
    [arity, loading, onAction],
  );
  const clearError = React.useCallback(() => setError(null), []);

  return {
    loading,
    error,
    onSubmit,
    clearError,
  };
};

export const useLogin = (defaultCountry, setStep) => {
  const dispatch = useDispatch();
  const loginAction = React.useCallback(
    async (userId) => {
      const { type } = await dispatch(login(userId, defaultCountry));
      if (type === USERID.USERNAME) {
        setStep(STEP.PASSWORD);
      } else {
        setStep(STEP.VALIDATE);
      }
    },
    [defaultCountry, setStep, dispatch],
  );
  return useAction(loginAction);
};

export const useValidate = (landscaping = false) => {
  const dispatch = useDispatch();
  const defaultCountry = useSelector(getDefaultCountry);
  const validateAction = React.useCallback(
    (userId, credentials) => dispatch(validate({
      userId,
      credentials,
      defaultCountry,
      landscaping,
    })),
    [defaultCountry, dispatch],
  );
  return useAction(validateAction, 2);
};

const guessCompanyLogo = debounce(async (name = '', callback) => {
  if (!name) {
    return;
  }

  const query = encodeURIComponent(name);
  const url = `https://autocomplete.clearbit.com/v1/companies/suggest?query=${query}`;
  const response = await fetch(url);
  if (!response.ok) {
    return;
  }

  const data = await response.json();
  if (!data || data.length === 0) {
    return;
  }

  const { logo } = data[0];
  const imageResponse = await fetch(logo);
  if (!imageResponse.ok) {
    return;
  }

  const blob = await imageResponse.blob();
  callback(blob);
});

export const useCompanyLogo = (name) => {
  const [logo, setLogo] = React.useState({
    value: null,
    guess: true,
  });

  React.useEffect(() => {
    if (logo.guess) {
      guessCompanyLogo(name, (newLogo) => {
        setLogo((prevLogo) => {
          if (!prevLogo.guess) {
            return prevLogo;
          }
          return {
            value: newLogo,
            guess: true,
          };
        });
      });
    }
  }, [logo.guess, name, setLogo]);

  const setFunc = React.useCallback((newLogo) => {
    setLogo({
      value: newLogo,
      guess: false,
    });
  }, [setLogo]);
  return [logo.value, setFunc];
};

export const useImageURL = (image) => {
  const [url, setUrl] = React.useState(null);
  React.useEffect(() => {
    URL.revokeObjectURL(url);
    if (image) {
      setUrl(URL.createObjectURL(image));
    } else {
      setUrl(null);
    }
    return () => URL.revokeObjectURL(url);
  }, [image]);
  return url;
};

export const useRegisterCompany = (done) => {
  const dispatch = useDispatch();
  const registerAction = React.useCallback(
    async (name, industry, logo) => {
      await dispatch(register({ name, industry, logo }));
      const companyData = { name, industry, logo };
      if (industry === LANDSCAPING_INDUSTRY) {
        companyData.terrachat = { landscaping: true };
      }
      done(companyData);
    },
    [dispatch],
  );
  return useAction(registerAction, 3);
};

export const useRegisterValidation = () => {
  const dispatch = useDispatch();
  const defaultCountry = useSelector(getDefaultCountry);
  const registerValidationAction = React.useCallback(
    (...args) => dispatch(registerValidation(...args, defaultCountry)),
    [dispatch, defaultCountry],
  );
  return useAction(registerValidationAction, 2);
};
