import {
  prop, evolve, map, pick,
} from 'ramda';
import { createSelector } from 'reselect';
import { isSafari } from 'src/data/util';
import { NAMESPACE, APP_INFO } from '../constants';
import { getCompany, sessionCompanies } from './companies';
import { getAgent, sessionAgent } from './agents';

export const getAppInfo = (state) => state[APP_INFO] || {};

export const getAWS = (state) => state[NAMESPACE].aws;

export const getSelectedCompanyId = (state) => state[NAMESPACE].selectedCompany;

export const getUserAgentId = createSelector(
  getCompany(getSelectedCompanyId),
  prop('userAgent'),
);

export const getUserAgent = createSelector(
  getUserAgentId,
  getAgent(getSelectedCompanyId, getUserAgentId),
  (userAgentId, userAgent) => userAgent || (userAgentId ? { id: userAgentId } : undefined),
);

export const getHasSessionData = createSelector(
  getAWS,
  getCompany(getSelectedCompanyId),
  (aws, company) => !!(aws && company),
);

export const getSessionData = createSelector(
  getAWS,
  getSelectedCompanyId,
  sessionCompanies,
  sessionAgent(getSelectedCompanyId, getUserAgentId),
  (
    aws,
    selectedCompany,
    companies,
    userAgent,
  ) => {
    const result = { aws, selectedCompany, companies };
    if (selectedCompany && userAgent) {
      result.agents = {
        [selectedCompany]: { [userAgent.id]: userAgent },
      };
    }
    return { [NAMESPACE]: result };
  },
);

const compressSessionData = evolve({
  [NAMESPACE]: evolve({
    companies: (companies) => {
      if (!companies) {
        return undefined;
      }
      const companyIds = Object.keys(companies);
      if (companyIds.length <= 5) {
        return companies;
      }
      return companyIds.reduce((result, id) => {
        if (companies[id].token) {
          return {
            ...result,
            [id]: pick(['id', 'token', 'userAgent'], companies[id]),
          };
        }
        if (companies[id].apikey) {
          return {
            ...result,
            [id]: pick(['id', 'apikey', 'userAgent'], companies[id]),
          };
        }
        return result;
      }, {});
    },
    agents: (agents) => (
      agents ? map(map(pick(['id', 'role', 'metadata'])), agents) : undefined
    ),
  }),
});

export const SessionSelector = (selector) => {
  const baseSelector = isSafari() ? createSelector(
    getSessionData,
    compressSessionData,
  ) : getSessionData;
  return selector ? (state) => ({
    ...selector(state),
    ...baseSelector(state),
  }) : baseSelector;
};
