import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Link } from 'react-router-dom';
import isMobile from 'is-mobile';
import { StyledLink } from './components/util';
import { useResources } from './connect/hooks';

const Header = ({ showRegister }) => {
  const mobile = isMobile();
  const { texts } = useResources();
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        maxWidth: '1080px',
        padding: '15px 10px 40px',
        alignItems: 'center',
        boxSizing: 'border-box',
      }}
    >
      <a
        href="https://www.twnel.com/"
        style={{
          display: 'flex',
          width: mobile ? '38px' : 'auto',
          overflow: 'hidden',
        }}
      >
        <img
          height="36"
          srcSet="
            https://twnelpublic.s3-us-west-2.amazonaws.com/Twnel_logo_colors%40320.png,
            https://twnelpublic.s3-us-west-2.amazonaws.com/Twnel_logo_colors%40640.png 2x,
            https://twnelpublic.s3-us-west-2.amazonaws.com/Twnel_logo_colors%401024.png 3x,
            https://twnelpublic.s3-us-west-2.amazonaws.com/Twnel_logo_colors%401280.png 4x,
          "
          src="https://twnelpublic.s3-us-west-2.amazonaws.com/Twnel_logo_colors%40320.png"
          alt="Twnel"
        />
      </a>
      <div style={{ flexGrow: 1 }} />
      <Switch>
        <Route path="/register">
          <StyledLink
            as={Link}
            to={(location) => ({ ...location, pathname: '/' })}
          >
            {texts('Manage your business')}
          </StyledLink>
        </Route>
        {showRegister ? (
          <Route>
            <StyledLink
              as={Link}
              to={(location) => ({ ...location, pathname: '/register' })}
            >
              {texts('Create a free account')}
            </StyledLink>
          </Route>
        ) : null}
      </Switch>
    </div>
  );
};

Header.propTypes = {
  showRegister: PropTypes.bool,
};

Header.defaultProps = {
  showRegister: false,
};

export default Header;
