import {
  curry, map, assoc, flatten, compose, forEach,
} from 'ramda';
import { batch } from 'react-redux';
import to from 'await-to-js';
import { AppError, networkError, catchNetworkError } from '@twnel/web-components';
import { companyNameToId, agentFields, allAgentFields } from '@twnel/utils-js/lib/web';
import { getCompany, getAllAgents, getAllBusinessUnits } from '../selectors';
import { NAMESPACE, UPDATE_COMPANY, UPDATE_SELECTED_COMPANY } from '../constants';
import {
  updateAgent, fetchCompanyAgents, fetchOnlineAgents, fetchAutolockAvailableAgents,
} from './agents';
import { updateBusinessUnit, fetchCompanyBusinessUnits } from './businessUnits';

const EXPIRATION_THRESHOLD = 20 * 1000;

export const updateCompany = (company) => ({
  type: UPDATE_COMPANY,
  payload: company,
});

export const setSelectedCompany = (selectedCompanyId) => ({
  type: UPDATE_SELECTED_COMPANY,
  payload: selectedCompanyId,
});

const fetchCompany = curry(async (api, company) => {
  const [error, result] = await to(api.companies.get(company));
  if (error) {
    throw networkError(error);
  }
  return result;
});

const updateCompanyRequest = (api, companyId, state, {
  expiration,
  includeAgents,
  includeAgentOnlineStatus,
  includeAgentAutolockStatus,
  includeBusinessUnits,
  pickAgentFields,
}) => {
  const promises = [];
  const company = getCompany(companyId, state);
  const companyMask = company || { id: companyId };
  const expired = !company
    || typeof company.lastUpdate !== 'number'
    || (expiration > 0 && company.lastUpdate < expiration);

  let companyPromise;
  if (expired) {
    companyPromise = fetchCompany(api, companyMask)
      .then(assoc('lastUpdate', Date.now() + EXPIRATION_THRESHOLD));
    promises.push(companyPromise.then(updateCompany));
  } else {
    companyPromise = Promise.resolve(company);
  }

  let agentsPromise;
  const agents = getAllAgents(companyId, state);
  if ((expired || !agents) && includeAgents) {
    agentsPromise = fetchCompanyAgents(api, companyMask, { pickFields: pickAgentFields });
    if (company?.userAgent) {
      const userAgentPromise = fetchCompanyAgents(api, companyMask, {
        agentId: company.userAgent,
        pickFields: allAgentFields,
      }).catch(catchNetworkError((error) => {
        if (error.status === 404) {
          throw AppError({
            title: 'Missing agent profile',
            body: 'We couldn\'t find your agent profile. Make sure you are registered as an agent in this company, and then reload the page to continue.',
          });
        }
        throw error;
      }));
      agentsPromise = Promise.all([
        agentsPromise,
        userAgentPromise,
      ]).then(([list, userAgent]) => ([
        ...list.filter(({ id }) => id !== company.userAgent),
        userAgent,
      ]));
    }
    if (includeAgentOnlineStatus) {
      agentsPromise = fetchOnlineAgents(api, companyMask, agentsPromise);
    }
    if (includeAgentAutolockStatus) {
      agentsPromise = fetchAutolockAvailableAgents(api, companyPromise, agentsPromise);
    }
    promises.push(agentsPromise.then(map(updateAgent(companyMask))));
  } else {
    agentsPromise = Promise.resolve(agents);
  }

  const businessUnits = getAllBusinessUnits(companyId, state);
  if ((expired || !businessUnits) && includeBusinessUnits) {
    promises.push(
      fetchCompanyBusinessUnits(api, companyMask)
        .then(map(updateBusinessUnit(companyMask))),
    );
  }

  return Promise.all(promises);
};

export const refreshCompany = (companyId, {
  expiration = Infinity,
  includeAgents = true,
  includeAgentOnlineStatus = false,
  includeAgentAutolockStatus = false,
  includeBusinessUnits = false,
  pickAgentFields = agentFields,
} = {}) => async (dispatch, getState, getContext) => {
  const { api, cacheStore } = getContext();
  const ongoingRequests = cacheStore.get(`${NAMESPACE}/refreshCompany`);
  if (ongoingRequests.get(companyId)) {
    return;
  }

  let updates;
  ongoingRequests.set(companyId, true);
  try {
    updates = await updateCompanyRequest(api, companyId, getState(), {
      expiration,
      includeAgents,
      includeAgentOnlineStatus,
      includeAgentAutolockStatus,
      includeBusinessUnits,
      pickAgentFields,
    });
  } catch (error) {
    ongoingRequests.delete(companyId);
    throw error;
  }

  ongoingRequests.delete(companyId);
  batch(() => compose(forEach(dispatch), flatten)(updates));
};

export const batchRefreshCompany = (companyId, {
  expiration = Infinity,
  includeAgents = true,
  includeAgentOnlineStatus = false,
  includeBusinessUnits = false,
  pickAgentFields = agentFields,
} = {}) => (dispatch, getState, getContext) => {
  const { api, cacheStore } = getContext();
  const request = updateCompanyRequest(api, companyId, getState(), {
    expiration,
    includeAgents,
    includeAgentOnlineStatus,
    includeAgentAutolockStatus: false,
    includeBusinessUnits,
    pickAgentFields,
  });

  const companyRequests = cacheStore.get(`${NAMESPACE}/batchRefreshCompany`);
  const { timeout, queue = [] } = companyRequests.get('queue') ?? {};
  clearTimeout(timeout);

  const queuedRequests = [...queue, to(request)];
  companyRequests.set('queue', {
    queue: queuedRequests,
    timeout: setTimeout(async () => {
      companyRequests.delete('queue');
      const results = await Promise.all(queuedRequests);
      const updates = results.reduce((list, [error, result]) => {
        if (error) {
          return list;
        }
        return [...list, flatten(result)];
      }, []);
      batch(() => compose(forEach(dispatch), flatten)(updates));
    }, 500),
  });
};

export const checkNameAvailability = (name) => async (dispatch, getState, getContext) => {
  const { api } = getContext();
  const companyId = companyNameToId(name);
  const [error] = await to(fetchCompany(api, { id: companyId }));
  if (error && error.status === 404) {
    return true;
  }
  if (error) {
    throw error;
  }
  return false;
};

export const refreshAgentOnlineStatus = (companyId) => async (dispatch, getState, getContext) => {
  const { api } = getContext();
  const companyMask = { id: companyId };
  const agents = getAllAgents(companyId, getState())
    || fetchCompanyAgents(api, companyMask);
  const onlineAgents = await fetchOnlineAgents(api, companyMask, agents);
  batch(() => onlineAgents.forEach(compose(dispatch, updateAgent(companyMask))));
};
