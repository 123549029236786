import { compose, prop, last } from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import isMobile from 'is-mobile';
import { TextInput, Avatar, LoadingButton } from '@twnel/web-components';
import { breakError } from '../connect/actions';
import { LANDSCAPING_INDUSTRY } from '../connect/constants';
import {
  useResources, useCompanyLogo, useImageURL, useRegisterCompany,
} from '../connect/hooks';
import Dropdown from './Dropdown';
import { StyledLink, InputField, AvatarInput } from './util';

export const INDUSTRY_LABELS = [
  ['FINANCIAL', 'Financial services'],
  ['NON_PROFIT', 'Non-profit'],
  ['TECH', 'Computers/Technology'],
  ['BUSINESS', 'Consulting/Business services'],
  ['EDUCATION', 'Education'],
  ['FOOD', 'Food/Beverages'],
  ['HEALTH', 'Health'],
  ['INSURANCE', 'Insurance'],
  ['SOFTWARE', 'Internet/Software'],
  [LANDSCAPING_INDUSTRY, 'Landscaping'],
  ['LEGAL', 'Legal/Law'],
  ['MEDIA', 'Media/News/Publishing'],
  ['RETAIL', 'Retail and consumer'],
  ['DIRECT_SALES', 'Direct Sales'],
  ['SMALL_BUSINESS', 'Small business'],
  ['TELCO', 'Telecommunication'],
  ['TRANSPORT', 'Transport/Freight'],
  ['TRAVEL', 'Travel/Leisure'],
  ['OTHER', 'Other'],
];

const CompayProfile = ({ hideImage, nextStep, landscaping }) => {
  const mobile = isMobile();
  const { texts } = useResources();

  const [industries, setIndustries] = React.useState([]);
  React.useEffect(() => {
    setIndustries(INDUSTRY_LABELS.map(compose(texts, last)));
  }, [texts]);

  const [name, setName] = React.useState(null);
  const [industry, setIndustry] = React.useState(null);
  const [logo, setLogo] = useCompanyLogo(name);

  const logoURL = useImageURL(logo);
  const registerProps = useRegisterCompany(nextStep);
  const {
    loading, error, onSubmit, clearError,
  } = registerProps;

  React.useEffect(() => {
    hideImage(!!error);
  }, [hideImage, error]);

  React.useEffect(() => {
    clearError();
  }, [name, industry, logo, clearError]);
  const [errorLabel, errorMessage] = breakError(error);

  return (
    <>
      <h1>
        {texts('Register')}
        <br />
        {texts('your business')}
      </h1>
      <p style={{ margin: '0 0 20px' }}>
        {texts('It\'s free and takes just a few seconds.')}
      </p>
      <InputField mobile={mobile}>
        <TextInput
          placeholder={texts('Company name')}
          onChange={compose(setName, prop('value'))}
          autoFocus
          boxed
        />
        <div>{errorLabel === 1 ? errorMessage : null}</div>
      </InputField>
      {landscaping ? null : (
        <InputField mobile={mobile}>
          <Dropdown
            label={texts('Industry')}
            options={industries}
            onChange={(index) => setIndustry(INDUSTRY_LABELS[index][0])}
          />
          <div>{errorLabel === 2 ? errorMessage : null}</div>
        </InputField>
      )}
      <InputField mobile={mobile}>
        <AvatarInput as="div">
          <Avatar
            id="placeholder2"
            avatarType="company"
            size={300}
            image={logoURL}
            editable
            updateImage={setLogo}
          />
        </AvatarInput>
        <div>{errorLabel === 3 ? errorMessage : null}</div>
      </InputField>
      <InputField
        mobile={mobile}
        style={{ margin: '20px 0' }}
      >
        <LoadingButton
          loading={loading}
          onClick={() => onSubmit(
            name,
            landscaping ? LANDSCAPING_INDUSTRY : industry,
            logo,
          )}
        >
          {texts('Register')}
        </LoadingButton>
        <div>{errorLabel === 0 ? errorMessage : null}</div>
      </InputField>
      <p
        style={{
          margin: '40px 0 20px',
          opacity: 0.76,
        }}
      >
        {texts('By signing up, you agree to the ')}
        <StyledLink
          href="https://www.twnel.com/wp-content/uploads/2019/01/TwnelTermsofService.pdf"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'inherit' }}
        >
          {texts('Terms of Service')}
        </StyledLink>
        {texts(' and ')}
        <StyledLink
          href="https://www.twnel.com/wp-content/uploads/2019/01/TwnelPrivacyPolicy.pdf"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'inherit' }}
        >
          {texts('Privacy Policy')}
        </StyledLink>
      </p>
    </>
  );
};

CompayProfile.propTypes = {
  hideImage: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  landscaping: PropTypes.bool,
};

CompayProfile.defaultProps = {
  landscaping: false,
};

export default CompayProfile;
