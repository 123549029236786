import React from 'react';
import isMobile from 'is-mobile';
import { ThemeProvider } from 'styled-components';
import { useTheme } from '@twnel/web-components';
import CompayProfile from 'src/ui/components/CompanyProfile';
import AgentProfile from 'src/ui/components/AgentProfile';

const imageColor = Math.random() < 0.5 ? '_Blue' : '';

export default function Register() {
  const theme = useTheme();

  const mobile = isMobile();
  const [hideImage, setHideImage] = React.useState(false);
  const [companyData, setCompanyData] = React.useState(null);

  let component;
  if (companyData) {
    component = (
      <AgentProfile
        hideImage={setHideImage}
        companyData={companyData}
        landscaping
      />
    );
  } else {
    component = (
      <ThemeProvider theme={{ ...theme, avatarColors: ['#9DBCA6', '#64889A'] }}>
        <CompayProfile
          hideImage={setHideImage}
          nextStep={setCompanyData}
          landscaping
        />
      </ThemeProvider>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        maxWidth: '1080px',
        alignItems: 'center',
        padding: '0 10px',
        boxSizing: 'border-box',
      }}
    >
      <div
        style={{
          width: '376px',
          padding: '20px',
          boxSizing: 'border-box',
        }}
      >
        {component}
      </div>
      {mobile ? null : (
        <>
          <div style={{ flexGrow: 1 }} />
          <img
            height="620"
            style={{
              zIndex: -5,
              opacity: hideImage ? 0.1 : 1.0,
              transition: 'opacity 0.2s ease-out',
            }}
            src={`https://twnelpublic.s3.us-west-2.amazonaws.com/Terra_Register${imageColor}.png`}
            alt=""
          />
        </>
      )}
    </div>
  );
}
