import {
  T as True, identity, prop, cond, isNil, always, values,
} from 'ramda';
import { createSelector } from 'reselect';
import { smartSelector, uncurrySelector } from '@twnel/web-components';

// (state → {a}) → (companyId | selector) → (state → {a})
export const getAllItemsById = uncurrySelector(3, (selector) => (companyId) => smartSelector(
  companyId,
  selector,
  prop,
));

// (state → {a}) → (companyId | selector) → (itemId | selector) → state → a
export const getItem = (
  selector,
  transform = identity,
) => uncurrySelector(3, (companyId) => (itemId) => smartSelector(
  getAllItemsById(selector, companyId),
  itemId,
  (items, id) => {
    if (!items) return undefined;
    return items[id] ? transform(items[id]) : undefined;
  },
));

// (state → {a}, functor f: a → b) → (companyId | selector) → state → [f b]
export const getAllItems = (
  selector,
  functor = values,
) => uncurrySelector(2, (companyId) => createSelector(
  getAllItemsById(selector, companyId),
  cond([
    [isNil, always([])],
    [True, functor],
  ]),
));
