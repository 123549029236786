import { assoc } from 'ramda';
import { UPDATE_BUSINESS_UNIT, DELETE_BUSINESS_UNIT } from '../constants';
import { listReducer } from './util';

const businessUnitsReducer = listReducer({
  updateSingle: UPDATE_BUSINESS_UNIT,
  deleteSingle: DELETE_BUSINESS_UNIT,
});

export default function (state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_BUSINESS_UNIT:
    case DELETE_BUSINESS_UNIT: {
      const { company, businessUnit } = payload;
      const { id: companyId } = company;
      const updateBusinessUnits = businessUnitsReducer(
        state[companyId],
        { type, payload: businessUnit },
      );
      return assoc(companyId, updateBusinessUnits, state);
    }
    default:
      return state;
  }
}
